/** Helper components for showing the wizard */
import {
  Box,
  Button,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel as BaseLabel,
  FormLabelProps,
  Input,
  InputProps,
} from '@chakra-ui/react'
import {
  Field as FormikField,
  FieldInputProps,
  FieldMetaProps,
  FieldProps,
  useFormikContext,
} from 'formik'
import { orderBy } from 'lodash'
import { ReactElement, ReactNode, useMemo, useState } from 'react'
import { HStack, StackProps } from '~src/components'

type WithDataDash<T> = T & { [key: `data-${string}`]: string }

///////////////////////////////////
// Field+label+helptext+error
///////////////////////////////////
export type FieldUIProps<T> = {
  /** temporary-ish for admin table values...red vs. showing error etc....obviously just red doesn't work for color blind folks */
  canBeRedUntouched?: boolean
  /** temporary for admin table values */
  hideErrorMsg?: boolean
  helperText?: ReactNode
  input: ReactElement
  label: string | ReactNode
  /** @deprecated Not fully figured out... */
  labelPos?: 'stacked' | 'inline'
  meta?: FieldMetaProps<T>
} & Omit<FormControlProps, 'children' | 'label'>

export function FieldUI<T>(props: FieldUIProps<T>) {
  let {
    canBeRedUntouched,
    hideErrorMsg,
    helperText,
    input,
    label,
    labelPos,
    meta,
    ...formControlProps
  } = props

  const isInline = labelPos === 'inline'

  return (
    <FormControl
      isInvalid={meta?.error && (canBeRedUntouched || meta?.touched)}
      position={null} // note: chakra v1 changed defaults which broke things upstream
      width={null} // note: chakra v1 changed defaults which broke things upstream
      {...formControlProps}
    >
      <Labeler label={label} inline={isInline} input={input} />
      {helperText && (
        <FormHelperText
          mt={isInline ? 1 : null}
          textAlign={isInline ? 'center' : null}
        >
          {helperText}
        </FormHelperText>
      )}
      {meta && !hideErrorMsg && (
        <Box minH={8} mt={2}>
          <FormErrorMessage data-cy="fieldui-error">
            {meta.error}
          </FormErrorMessage>
        </Box>
      )}
    </FormControl>
  )
}

const Labeler: React.FC<{
  label: ReactNode | string
  inline: boolean
  input: ReactNode
}> = ({ label, inline, input }) => {
  if (typeof label === 'string') {
    label = (
      <FormLabel mb={inline ? 0 : null} mr={0}>
        {label}
      </FormLabel>
    )
  }
  const children = (
    <>
      {label}
      {input}
    </>
  )

  return inline ? <HStack gap={2}>{children}</HStack> : children
}

const capitalizeFirst = (str: string) =>
  !str ? str : str[0].toUpperCase() + str.slice(1)

export const FormLabel = (props: FormLabelProps) => {
  let { children, ...rest } = props
  // maybe use textTransform instead?
  if (typeof children === 'string') {
    children = capitalizeFirst(children)
  }
  return (
    <BaseLabel fontSize="md" fontWeight={200} opacity={0.8} {...rest}>
      {children}
    </BaseLabel>
  )
}

type SimpleTextFieldProps = {
  field: FieldInputProps<string>
  helperText?: ReactNode
  inputProps?: WithDataDash<InputProps>
  label: string
  meta: FieldMetaProps<string>
}
export function SimpleTextField(props: SimpleTextFieldProps) {
  const { field, helperText, inputProps = {}, label, meta } = props

  return (
    <FieldUI
      helperText={helperText}
      // Avoid uncontrolled React error when value is undefined
      input={
        <Input
          type="text"
          {...field}
          value={field.value ?? ''}
          {...inputProps}
        />
      }
      label={label}
      meta={meta}
      w="100%"
    />
  )
}

type SimpleTextField2Props = {
  helperText?: ReactNode
  inputProps?: WithDataDash<InputProps>
  label: string
  name: string
}
// todo: because the subforms DO use <Formik />
export function SimpleTextField2(props: SimpleTextField2Props) {
  const { helperText, inputProps = {}, label, name } = props

  return (
    <FormikField name={name}>
      {({ field, meta }: FieldProps) => (
        <FieldUI
          helperText={helperText}
          // Avoid uncontrolled React error when value is undefined
          input={
            <Input
              type="text"
              {...field}
              value={field.value ?? ''}
              {...inputProps}
            />
          }
          label={capitalizeFirst(label)}
          meta={meta}
          w="100%"
        />
      )}
    </FormikField>
  )
}

export const ButtonStack = (props: StackProps) => {
  return <HStack gap={1} {...props} />
}

type SingleInputFormProps = {
  autoFocus?: boolean
  beforeChange?(value: string): string
  clearOnSubmit?: boolean
  formControlProps?: FormControlProps
  initialValue?: string
  label?: ReactNode
  onSubmit(value: string): void
  placeholder?: string
  size?: InputProps['size']
  submitLabel?: ReactNode | ((value: string) => ReactNode)
  validate?(value: string): string
}

/**
 * Render a single input with submit button
 */
export function SingleInputForm(props: SingleInputFormProps) {
  const {
    autoFocus,
    beforeChange,
    clearOnSubmit,
    formControlProps,
    initialValue,
    label,
    onSubmit,
    placeholder,
    size = 'sm',
    submitLabel = 'Add',
    validate,
  } = props

  const [value, setValue] = useState(initialValue ?? '')

  let error = useMemo(() => {
    return validate?.(value)
  }, [validate, value])

  const isSubmittable = !!value && !error

  let submit = () => {
    clearOnSubmit && setValue('') // clear value
    onSubmit(value)
  }

  return (
    <FormControl
      isInvalid={!!error}
      position={null} // note: chakra v1 changed defaults which broke things upstream
      width={null} // note: chakra v1 changed defaults which broke things upstream
      {...formControlProps}
    >
      {label}
      <HStack gap={1}>
        <Input
          autoFocus={autoFocus}
          data-cy="sif-input" // todo:
          type="text"
          onKeyPress={(event) => {
            if (event.code === 'Enter' && isSubmittable) {
              submit()
            }
          }}
          onChange={(event) => {
            let value = event.target.value
            if (beforeChange) {
              value = beforeChange(value)
            }
            setValue(value)
          }}
          placeholder={placeholder}
          size={size}
          value={value}
        />
        <Button isDisabled={!isSubmittable} onClick={submit} size={size}>
          {typeof submitLabel === 'function' ? submitLabel(value) : submitLabel}
        </Button>
      </HStack>
      <Box minHeight={4} mt={2}>
        <FormErrorMessage>{error}</FormErrorMessage>
      </Box>
    </FormControl>
  )
}

export type Sorter<V> = {
  sorter(item: V): any
  keyer(item: V): string | number
}

type UnwrapSorter<S> = S extends Sorter<infer U> ? U : never

/**
 * Helpers for sorted formik array
 */
export function useFormikArrayHelpers<
  S extends Record<string, Sorter<any>>,
  K extends keyof S & string,
>(sorters: S, key: K) {
  const { setFieldValue, values } = useFormikContext<{
    [K in keyof S]: UnwrapSorter<S[K]>[]
  }>()

  type T = UnwrapSorter<S[K]>

  const { sorter, keyer } = sorters[key]

  const sorted = values[key] as T[]

  const usedSet = useMemo(() => {
    return new Set(sorted.map(keyer))
  }, [values])

  const setAndSortArray = (value: T[]) => {
    setFieldValue(key, orderBy(value, sorter))
  }

  const addToArray = (value: T) => {
    return setAndSortArray([...sorted, value])
  }

  const filterArray = (filter: (item: T) => boolean) => {
    return setAndSortArray(sorted.filter(filter))
  }

  return { addToArray, filterArray, setAndSortArray, sorted, usedSet }
}
