import {
  Curriculum,
  Margin,
  PacketmetaC2,
  PacketType,
  Padding,
  PageItem,
  Pos,
  PublishStage,
  Variant
} from '@paper/schema'
import { stubPQs } from '@paper/stubs'
import times from 'lodash/times'
import { contentToFormik, SB } from '../wizard/entryBaseData'

export { ContentFormSchema, SB } from '../wizard/entryBaseData'

type PacketsStubProps = {
  contentId: string
  curriculumId?: string
  curStepBit: SB
  isNew: boolean
  omitAnswerKey?: boolean
  pdfLength: number
  pubStage?: PublishStage
  type: PacketType
}

export function getPacketsStub(props: PacketsStubProps) {
  const {
    contentId,
    curriculumId,
    curStepBit,
    isNew,
    omitAnswerKey,
    pdfLength,
    pubStage = 'published',
    type,
  } = props
  const isTicket = type === 'ticket'
  const pageCount = isTicket ? 1 : pdfLength

  // initialize to empty
  let content = contentToFormik()

  // construct based on which step we're on
  // that is, if testing a step (and not new),
  // we want everything prior to that step filled out
  let shouldStub = (candidateStep: SB) => candidateStep < curStepBit || !isNew

  if (shouldStub(SB.PACKET_TYPE)) {
    content.pages = times(pageCount, () => ({ items: [] }))
    content.type = type
    // the ticket is at index 2, assessments are initialized to a single part
    content.parts = isTicket ? [2] : [0]
  }
  if (shouldStub(SB.PACKET_NAME)) {
    content.name = `Dev Stub ${type[0].toUpperCase()}`
  }
  if (shouldStub(SB.QR)) {
    content.style = {
      margin: Margin.qtr,
      padding: Padding.eighth,
      position: Pos.tr,
      variant: Variant.compact,
    }
  }
  if (type === 'assessment') {
    if (shouldStub(SB.PARTS)) {
      content.parts = [0, 2, 4]
    }

    if (!omitAnswerKey) {
      // todo: conditional stubbing for this?
      content._importName = 'Geometry.IAHS.Q02.22-23.USI' //'Math.8.Sth.Stub.Pad.ding'

      let { passages, questions } = stubPQs()

      if (shouldStub(SB.QUESTIONS)) {
        content._import = {
          srcs: [{ id: '0', inst: 'XYZ' }],
          time: new Date().valueOf(),
        }
        content.questions = questions
      }
      if (shouldStub(SB.PASSAGES)) {
        content.passages = passages
      }
      if (shouldStub(SB.PAGES)) {
        // hardcoding something for now
        content.pages[0].items.push({ id: passages[0].id, type: 'passage' })
        content.pages[1].items.push(
          { id: passages[0].id, type: 'passage' },
          { id: questions[0].id, type: 'question' }
        )
        content.pages[2].items.push({ id: passages[1].id, type: 'passage' })
        content.pages[3].items.push(
          ...[1, 2].map(
            (idx): PageItem => ({ id: questions[idx].id, type: 'question' })
          )
        )
        content.pages[3].items.push({ id: questions[3].id, type: 'question' })
      }
    }
  } else if (type === 'ticket') {
    if (shouldStub(SB.STDS)) {
      // todo:
    }
  }

  // a bunch of scenarios in here (printed, published, etc.)
  let lastUpdate = { time: new Date().valueOf(), user: 'stub@ponder.co' }
  let ed = '22-23'
  let curriculumName = curriculumId
    ? `${curriculumId} (stubbed)`
    : `Stubbed Curriculum ${ed} Radioactive`

  let curriculum: Curriculum = {
    _status: 'active',
    ed,
    family: curriculumName,
    id: curriculumId ?? 'stub',
    levels: ['8'],
    name: curriculumName,
    subject: 'Math',
    syId: 2023,
    variant: 'stub',
  }

  const hasBeenPubd = new Set<PublishStage>(['published', 'recalled']).has(pubStage)
  let hasNumber = hasBeenPubd
  let hasLink = hasBeenPubd
  let isPrinted = hasBeenPubd

  let packetmeta: PacketmetaC2 = {
    _linkExternal: hasLink ?  'https://www.example.com' : null,
    _printed: isPrinted ? { first: new Date(2022, 1, 1).valueOf() } : null,
    _pub: pubStage,
    _updates: {
      answerKey: lastUpdate,
      crossPacket: lastUpdate,
      last: lastUpdate,
      pdf: lastUpdate,
      publish: hasBeenPubd ? lastUpdate : null,
      stds: lastUpdate,
    },
    _uploaded: true,
    // todo: should parse off non-content fields
    // todo: images aren't directly used with the new api, so maybe get rid of them?
    content: { images: [], ...content },
    contentId,
    curriculum,
    curriculumId: curriculumId ?? 'stub',
    id: 'stub',
    number: hasNumber? '1.10' : null,
    src: null,
  }
  return { curriculum, packets: [packetmeta], formValues: packetmeta.content }
}
