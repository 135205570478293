import { BoxProps, useToken } from '@chakra-ui/react'
import { fromEntries } from '@paper/utils'
import { chunk } from 'lodash'
import { createContext, ReactNode, useContext, useState } from 'react'

const SeriesKeys = ['delay', 'proc', 'velo'] as const
type SeriesKey = (typeof SeriesKeys)[number]
type Series = {
  color: Record<'normal' | 'warn' | 'clamp', string>
  warnAt: number
}

type ScanVizDelayGraphContext = {
  series: {
    meta: { [key in SeriesKey]: Series }
    onChange(next: SeriesKey[]): void
    selected: readonly SeriesKey[]
  }
}

const ScanVizDelayGraphContext =
  createContext<ScanVizDelayGraphContext>(undefined)
export const useScanVizDelayGraphContext = () =>
  useContext(ScanVizDelayGraphContext)

type ScanVizDelayGraphProviderProps = { children: ReactNode }

// todo: maybe combine with ScanVizProvider?
export function ScanVizDelayGraphProvider(
  props: ScanVizDelayGraphProviderProps
) {
  const { children } = props
  const colors = useColors()

  const [selectedSeries, setSelectedSeries] = useState(SeriesKeys)

  const ctx: ScanVizDelayGraphContext = {
    series: {
      meta: {
        delay: { color: colors.delay, warnAt: 10 },
        proc: { color: colors.proc, warnAt: 5 },
        velo: { color: colors.velo, warnAt: 45 },
      },
      onChange: setSelectedSeries as any,
      selected: selectedSeries,
    },
  }
  return (
    <ScanVizDelayGraphContext.Provider value={ctx}>
      {children}
    </ScanVizDelayGraphContext.Provider>
  )
}

// so i can use them in svg...copy/paste with ScanVizSettings
const useColors = () => {
  const tokens = [
    // delay
    'blue.500',
    'yellow.500',
    'red.500',
    // proc
    'blue.200',
    'yellow.300',
    'red.200',
    // velo
    'green.500',
    'orange.500',
    'pink.500',
  ] satisfies BoxProps['color'][]

  // Resolve tokens
  const tokenResults = useToken('colors', tokens) as string[]
  // Chunk result
  const colorSets = chunk(tokenResults, 3)
  // Organize into sets
  const result = fromEntries(
    SeriesKeys.map((key, setIdx) => {
      const [normal, warn, clamp] = colorSets[setIdx]
      return [key, { normal, warn, clamp }]
    })
  )

  return result
}
