/** note: This file is ill-advisedly generated, see `_intlCodeGenSrc.ts` */

const IntlDTFormatters = {
  datetime: new Intl.DateTimeFormat(undefined, {
    dateStyle: 'medium',
    timeStyle: 'short',
  }),
  moDay: new Intl.DateTimeFormat(undefined, {
    month: 'short',
    day: 'numeric',
  }),
  shortshort: new Intl.DateTimeFormat(undefined, {
    dateStyle: 'short',
    timeStyle: 'short',
  }),
}
/**
 * Intl.DateTimeFormat presets
 */
export const IntlDT = {
  /** default datetime format */
  datetime: (date) => IntlDTFormatters.datetime.format(date),
  /** short month-day */
  moDay: (date) => IntlDTFormatters.moDay.format(date).replace(' ', '-'),
  /** short date and time */
  shortshort: (date) => IntlDTFormatters.shortshort.format(date),
} as const satisfies Record<string, Intl.DateTimeFormat['format']>
