import { ButtonGroup, Icon } from '@chakra-ui/react'
import { IcoUnscored } from '@paper/icons'
import { entries } from '@paper/utils'
import { HStack, ToggleGroup } from '~src/components'
import { useScanVizDelayGraphContext } from './scanVizDelayGraphProvider'

type ScanVizDelayLegendProps = {}

export function ScanVizDelayLegend(props: ScanVizDelayLegendProps) {
  const { series } = useScanVizDelayGraphContext()

  return (
    <HStack bg="rgba(0,0,0,.5)">
      <ToggleGroup.Root
        colorScheme="blackAlpha"
        onChange={series.onChange}
        preventNone={true}
        shape="90deg"
        size="xs"
        type="multiple"
        value={series.selected as any}
      >
        <ButtonGroup isAttached={true}>
          {entries(series.meta).map(([key, ser]) => {
            return (
              <ToggleGroup.Button
                key={key}
                border="none"
                color="white"
                sx={{
                  // these seem to be broken for blackAlpha
                  _active: { bg: 'blackAlpha.900' },
                  _hover: { bg: 'blackAlpha.800' },
                }}
                value={key}
              >
                {key}
                <HStack
                  filter={
                    !series.selected.includes(key)
                      ? 'grayscale(100%)'
                      : undefined
                  }
                  pointerEvents="none"
                  transition="filter .4s ease-in"
                >
                  {entries(ser.color).map(([legendKey, color]) => (
                    <Icon as={IcoUnscored} color={color} key={color} />
                  ))}
                </HStack>
              </ToggleGroup.Button>
            )
          })}
        </ButtonGroup>
      </ToggleGroup.Root>
    </HStack>
  )
}
