import { Box, BoxProps, Grid, Icon, IconProps, Tooltip } from '@chakra-ui/react'
import {
  IcoCalendar,
  IcoCheckmark,
  IcoDiscountIlluminate,
  IcoExclamationTriangle,
  IcoExternalSIS,
  IcoLoading,
  IcoScheduled,
  IcoScheduledUn,
  IcoX,
} from '@paper/icons'
import { useLink } from '@paper/route'
import { LoadRun, LoadStatus } from '@paper/schema'
import { ENDASH, parseZdtToDate } from '@paper/utils'
import { capitalize, mapValues } from 'lodash'
import { Fragment, ReactNode } from 'react'
import {
  BLink,
  HStack,
  StackProps,
  TextStack,
  TooltippedIcon,
  Txt,
  VStack,
} from '~src/components'
import { Routes } from '~src/routelist'
import {
  formatRelativeDate,
  formatUnits,
  getRelativeDateParts,
} from '~src/utils/messages'
import { IcoStd } from '../swMenu/swMenuIcons'
import { SystemAlertIcon } from './systemStatusIndicator'
import { useSystemStatus } from './systemStatusProvider'

const ROW = {
  Header: 1,
  LastSuccess: 2,
  NextScheduled: 3,
  RecentLabel: 4,
  RecentStart: 5,
}

const RC = mapValues(ROW, (rowStart) => (colIdx = 0, rowOffset = 0) => ({
  gridColumn: 1 + colIdx,
  gridRow: rowStart + rowOffset,
}))

type LoaderSchedGridProps = {}

export function LoaderSchedGrid(props: LoaderSchedGridProps) {
  const { qResult } = useSystemStatus()
  const refDate = qResult.data?.fetchedAt
  const data = qResult.data?.load
  const jobs = data?.jobs
  const loadYear = data?.loadYear
  const loadSched = data?.loadSched

  // job slots
  let slots = initSlots(
    {
      icon: IcoExternalSIS,
      top: 'Enrollments',
      bottom: 'PowerSchool',
      data: jobs.enrollments,
    },
    {
      icon: IcoDiscountIlluminate,
      top: 'Scores',
      bottom: 'Illuminate',
      data: jobs.scores,
    },
    {
      icon: IcoStd,
      top: 'Standards',
      bottom: 'Illuminate',
      data: jobs.stds,
    }
  )

  let body: ReactNode

  if (!loadYear) {
    body = (
      <IcondText data-cy="between-years" justifySelf="start" icon={IcoCalendar}>
        You're currently in between school years
      </IcondText>
    )
  } else if (!loadSched?.length) {
    // todo: this should probably override the "loaded daily notes..."
    body = (
      <IcondText data-cy="empty-sched" justifySelf="start" icon={IcoScheduled}>
        No scheduled runs
      </IcondText>
    )
  } else {
    const headerColumn = (
      <>
        <Txt {...RC.LastSuccess()}>Last success</Txt>
        <Txt {...RC.NextScheduled()}>Next scheduled</Txt>
        <Txt {...RC.RecentLabel()}>Last 24 hours</Txt>
        {data?.recentRows.map((p, idx) => {
          const containerProps = RC.RecentStart(0, idx)
          return (
            <HStack
              key={`slot.${idx}`}
              data-cy={`row-head-recent`}
              gap={0}
              {...containerProps}
            >
              <TooltippedIcon
                aria-label={p.sched ? 'Scheduled' : 'Unscheduled'}
                as={p.sched ? IcoScheduled : IcoScheduledUn}
                fontSize="sm"
                tooltipProps={{ placement: 'start' }}
              />
              <DateStack
                containerProps={{ minWidth: '60px' }}
                date={p.date}
                vs={refDate}
              />
            </HStack>
          )
        })}
      </>
    )
    body = (
      <>
        {headerColumn}
        {slots.map((job, idx) => (
          <LoadJobColumn
            key={idx}
            colIdx={idx + 1}
            refDate={refDate}
            {...job}
          />
        ))}
      </>
    )
  }

  return (
    <Grid
      gridAutoRows="minmax(24px, auto)"
      gridColumnGap={2.5}
      gridRowGap={3}
      gridTemplateColumns="repeat(4, 1fr)"
      placeItems="center"
      userSelect="none"
    >
      {body}
    </Grid>
  )
}

type IcondTextProps = StackProps & { icon: any }

/**
 * todo: chakra may already have something suitable?...it's a bit tag-like
 * todo: also need to be able specify icon props if not purely decorative...
 */
function IcondText(props: IcondTextProps) {
  const { children, icon, ...rest } = props
  return (
    <HStack gap="1.5" {...rest}>
      <Icon as={icon} aria-hidden="true" />
      {children}
    </HStack>
  )
}

type LoaderJobUISlot = {
  icon: any
  top: string
  bottom: string
  data: LoadStatus
}

/** type helper */
function initSlots(...slots: LoaderJobUISlot[]): LoaderJobUISlot[] {
  return slots
}

type LoadJobColumnProps = LoaderJobUISlot & { colIdx: number; refDate: number }

function LoadJobColumn(props: LoadJobColumnProps) {
  const { bottom, colIdx, data, icon, refDate, top } = props

  return (
    <>
      <Fragment key={`${colIdx}.head`}>
        <HStack gap={2} {...RC.Header(colIdx)} py={1}>
          <Icon as={icon} />
          <TextStack display="inline-flex" fontSize="sm">
            <TextStack.Top>{top}</TextStack.Top>
            <TextStack.Bottom fontStyle="italic" pr={1} variant="compact">
              {bottom}
            </TextStack.Bottom>
          </TextStack>
        </HStack>
        <LastSuccessCell
          containerProps={RC.LastSuccess(colIdx)}
          data={data?.lastSuccess}
          fetchedAt={refDate}
        />
        <Txt
          data-has-next={String(!!data?.nextSched)}
          {...RC.NextScheduled(colIdx)}
        >
          {data?.nextSched
            ? formatRelativeDate(parseZdtToDate(data.nextSched.zdt), refDate)
            : ENDASH}
        </Txt>
        {data?.recentSlots.map((row, rowIdx) => {
          return (
            <Box
              {...RC.RecentStart(colIdx, rowIdx)}
              key={`${colIdx}.${rowIdx}`}
            >
              <JobCell data={row} />
            </Box>
          )
        })}
      </Fragment>
    </>
  )
}

type JobCellProps = { data: LoadRun }

function JobCell(props: JobCellProps) {
  const { data } = props

  const status = data?.status ?? 'none'

  const iconProps: IconProps = {
    'aria-label': status,
    fontSize: 'sm',
  }

  let result: ReactNode = ENDASH
  if (data?.status === 'success') {
    result = <Icon {...iconProps} as={IcoCheckmark} color="green.500" />
  } else if (data?.status === 'fail' || data?.status === 'not-run-locked') {
    result = <Icon {...iconProps} as={IcoX} color="red.500" />
  } else if (data?.status === 'locked') {
    result = <Icon {...iconProps} as={IcoLoading} color="yellow.500" />
  } else if (data?.jobSet.sched) {
    result = (
      <Tooltip label="Missing run" offset={[0, 2]} placement="end">
        <SystemAlertIcon
          aria-label="Missing run"
          data-cy="load-alert-no-sched-run"
          ifNormal="nothing"
          size="xl"
          status="alert"
        />
      </Tooltip>
    )
  }

  return <>{result}</>
}

type DateStackProps = {
  containerProps?: BoxProps
  date: Date | number
  vs: Date | number
}

function DateStack(props: DateStackProps) {
  const { containerProps = {}, date, vs } = props
  const dateParts = getRelativeDateParts(date, vs)
  return (
    <TextStack textAlign="center" {...containerProps}>
      <TextStack.Top>{capitalize(dateParts.date)}</TextStack.Top>
      <TextStack.Bottom>{dateParts.time}</TextStack.Bottom>
    </TextStack>
  )
}

type LastSuccessCellProps = {
  containerProps: BoxProps
  data: LoadStatus['lastSuccess']
  fetchedAt: number
}

function LastSuccessCell(props: LastSuccessCellProps) {
  const { containerProps, data, fetchedAt } = props
  const linkProps = useLink(Routes.setup_issues.mergeAction({}))
  return (
    <VStack
      {...containerProps}
      data-has-success={String(!!data?.runStart)}
      gap={0}
    >
      {!data?.runStart ? (
        <Txt fontStyle="italic">None this year</Txt>
      ) : (
        <>
          {formatRelativeDate(data.runStart, fetchedAt)}
          <BLink
            {...linkProps}
            isDisabled={!data?.issueCount}
            justifyContent="start"
            leftIcon={
              !!data.issueCount && (
                <Icon as={IcoExclamationTriangle} color="orange.300" />
              )
            }
            variant="ghost"
            size="xs"
          >
            {formatUnits(data.issueCount, 'issue')}
          </BLink>
        </>
      )}
    </VStack>
  )
}
