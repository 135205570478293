import { Teacher } from '@paper/schema'
import { BIGDOT, XOR } from '@paper/utils'
import {
  differenceInCalendarDays,
  format,
  formatRelative,
} from '@paper/utils/date'
import { IntlNum } from '~src/intl'

export const title = process.env.NODE_ENV === 'production' ? 'Paper' : ':2345'

export const makeTitle = (...segments: string[]) => {
  // add prefix if empty or dev
  if (segments.length === 0 || process.env.NODE_ENV !== 'production') {
    segments = [title, ...segments]
  }
  return segments.join(' | ')
}

export type SinglePlural = string | [single: string, plural: string]

export const pluralS = (label: SinglePlural, amount: number) => {
  let [single, plural] = Array.isArray(label) ? label : [label, `${label}s`]
  return amount === 1 ? single : plural
}

/**
 * @example
 * formatUnits(1, 'page')
 * // 1 page
 * formatUnits(2, 'page')
 * // 2 pages
 * formatUnits(NaN, 'page')
 * // ?? pages
 */
export const formatUnits = (amt: number, label: SinglePlural, intl = false) => {
  const numberPart = isNaN(amt) ? '??' : intl ? IntlNum.plain(amt) : amt
  return `${numberPart} ${pluralS(label, amt)}`
}

export const formatPercent = (num: number, den: number, ifNan?: string) => {
  if (!den) {
    return ifNan
  }
  const pct = `${Math.round((100 * num) / den)}%`
  // Handle %s that round to 100
  if (pct === '100%' && den - num > Number.EPSILON) {
    return '99%+'
  }
  return pct
}

/**
 * @deprecated todo: reconcile with `formatPercent`
 */
export const formatPercent2 = (num: number, den: number) => {
  if (den === 0) {
    return null
  }
  let value = (100 * num) / den
  let digits = value < 1 ? 2 : value < 10 ? 1 : 0
  let pow = 10 ** digits
  let rounded = Math.round(value * pow) / pow
  return `${rounded}%`
}

/**
 * Returns date-fns `formatRelative` with 'last' and 'at' removed for a more compact string
 * When it's obvious from context that the date is in the past, there's no need for 'last'
 * @param date Date to compare
 * @param now Optionally specify date compared against
 */
export const formatPastVsNow = (date: number | Date, now?: number | Date) => {
  return !date
    ? undefined
    : formatRelative(date, now ?? new Date())
        .replace('last ', '')
        .replace(' at', '')
}

// todo: move/cleanup
export type FormattedDateParts = {
  date: string
  time: string
}
export function getRelativeDateParts(
  date: number | Date,
  baseDate: number | Date = new Date()
): FormattedDateParts {
  // Get the formatted time portion
  const time = format(date, 'p') // 'p' will return time like "3:45 PM"

  // Calculate the difference in days between the date and the baseDate
  const dayDifference = differenceInCalendarDays(baseDate, date)

  const absDayDifference = Math.abs(dayDifference)

  let datePart = ''

  if (dayDifference === -1) {
    datePart = 'Tomorrow'
  } else if (dayDifference === 0) {
    datePart = 'Today'
  } else if (dayDifference === 1) {
    datePart = 'Yesterday'
  } else if (absDayDifference > 1 && absDayDifference < 7) {
    // If the difference is within the past week, return the day of the week
    datePart = format(date, 'EEEE') // "Monday", "Tuesday", etc.
  } else {
    // Otherwise, just return the full date (e.g., "October 10")
    datePart = format(date, 'PP')
  }

  return { date: datePart, time: time }
}

export function formatRelativeDate(
  date: number | Date,
  baseDate: number | Date = new Date()
): string {
  const parts = getRelativeDateParts(date, baseDate)
  return `${parts.date} ${parts.time}`
}

type SampleArrayProps<T> = {
  /** Defaults to ' ● ' */
  delimiter?: string
  /** Optional return value if array is null or empty, defaults to `null` */
  empty?: string
  /** Items */
  items: T[]
  /** Defaults to 3 */
  length?: number
  /** toString */
  toString(item: T): string
}

const DefaultDelimiter = ` ${BIGDOT} `

/**
 * Returns a sampling of the `items`
 * @example
 * getSampleString({ items: students, toString: (item) => item.lastFirst })
 * > 'Aaronson, Aaron ● Bridges, Bridget ● Coolidge, Calvin'
 */
export function getSampleString<T>(props: SampleArrayProps<T>): string {
  return (
    props.items
      ?.slice(0, props.length ?? 3)
      .map(props.toString)
      .join(props.delimiter ?? DefaultDelimiter) || props.empty
  )
}

getSampleString.DefaultDelimiter = DefaultDelimiter

type FormatScanlogPacketProps = {
  course: string
  packet: XOR<{ number: string }, { packetNumber: string }>
  teacher: Pick<Teacher, 'lastName'>
}
export const getScanlogPacketLabel = (props: FormatScanlogPacketProps) => {
  const { course, packet, teacher } = props
  const pieces = [
    teacher.lastName,
    course,
    packet.number ?? packet.packetNumber,
  ]
  return pieces.join(' ‣ ')
}

export const NO_STD = 'No standard'

/** Rounds a ticket score to a decimal place */
export const formatScore = (score: number) => {
  if (score == null) {
    return null
  } else if (Number.isInteger(score)) {
    return score.toFixed(0)
  } else {
    return score.toFixed(1)
  }
}
