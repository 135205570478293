// todo: workaround for: https://github.com/parcel-bundler/parcel/issues/9676
// todo: and https://github.com/date-fns/date-fns/issues/3744
import { addDays } from 'date-fns/addDays'
import * as _format from 'date-fns/format'
export { addDays } from 'date-fns/addDays'
export { addHours } from 'date-fns/addHours'
export { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays'
export { formatDistance } from 'date-fns/formatDistance'
export { formatDuration } from 'date-fns/formatDuration'
export { formatRelative } from 'date-fns/formatRelative'
export { intervalToDuration } from 'date-fns/intervalToDuration'
export { startOfDay } from 'date-fns/startOfDay'
export { startOfHour } from 'date-fns/startOfHour'

export const { format, formatDate } = _format

/**
 * @example
 * addDaysYYMMDD('2024-10-31', 1) // => '2024-11-01'
 * addDaysYYMMDD(undefined, -1) // => yesterday's date in 'yyyy-MM-dd' format
 */
export const addDaysYYMMDD = (dateStr?: string, days: number = 0) => {
  const baseDate = dateStr ? new Date(dateStr) : new Date()
  return formatDate(addDays(baseDate, days), 'yyyy-MM-dd')
}
