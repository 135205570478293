import { ScanVizBatch } from '@paper/schema'
import { defineApi } from '../defineApi'

export const scan_viz = defineApi<{
  method: 'post'
  body: {
    endDate: number
  }
  result: ScanVizBatch[]
}>({
  method: 'post',
  urlFactory: `unstable-scan-viz`,
})
