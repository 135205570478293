export { C, get_ids } from './mongo/collections'
export type { CM } from './mongo/collections'
export * from './paths/s3'
export * from './types/auth'
export * from './types/illuminate'
export * from './types/location'
export * from './types/packetmeta'
export * from './types/pdf'
export * from './types/print'
export * from './types/school'
export * from './types/teacher'
export * from './types/types.crossnetwork'
export * from './types/types.download'
export * from './types/types.fixit'
export * from './types/types.imgp'
export * from './types/types.load'
export * from './types/types.packet'
export * from './types/types.satellite'
export * from './types/types.scanlog'
export * from './types/types.setup'
export * from './types/types.std'
export * from './types/types.xpacket'
export { Transit, V }

/** An attempt to avoid polluting @paper/schema */
import * as Transit from './types/types.transit'
// todo: is the below still true?
// TODO: @paper/api babel-loader apparently doesn't support `export * as` yet
import * as V from './validation'
