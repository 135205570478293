import { ScanBatch } from '@paper/schema'
import { mapValues } from 'lodash'

type BatchWithTs = Pick<ScanBatch, 'scanDate' | 'ts'>

export const calcScanBatchTimes = (batch: BatchWithTs) => {
  // Timing calc definitions
  const calcs = {
    delay: (p) => p.ts.initial - p.scanDate,
    proc: (p) => p.ts.settled - p.ts.initial,
  } as const satisfies Record<string, (p: BatchWithTs) => number>

  return mapValues(calcs, (c) => Math.round(c(batch) / 1000))
}
