import { ScanVizBatch } from '@paper/schema'

/**
 * Calculates the scan 'velocity' at batch time
 * which is the sum of scans in a given window before the batch.
 */
export function calcScanVelos(batches: ScanVizBatch[]) {
  // Configuration: Time window for scan count velo
  const veloWindowMinutes = 60
  const veloWindowMs = veloWindowMinutes * 60_000

  const velos: number[] = []

  for (let i = 0; i < batches.length; i++) {
    const batch = batches[i]
    const currentScanDate = batch.scanDate

    let sumScansInWindow = 0

    // Loop for summing scan counts within the scan count window
    for (let j = i; j >= 0; j--) {
      const scanDateDifference = currentScanDate - batches[j].scanDate

      if (scanDateDifference <= veloWindowMs) {
        sumScansInWindow += batches[j].scanCount
      } else {
        break // No need to check earlier dates for scan counts
      }
    }

    velos.push(sumScansInWindow)
  }

  return velos
}
