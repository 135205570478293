import { QCell } from '@paper/schema'
import { XOR } from '@paper/utils'
import { defineApi } from '../defineApi'

export const unstable_crossnetwork_list = defineApi<{
  method: 'post'
  body: { syId: number } & XOR<
    { contentId: string }, // list for grid
    { packetId: string; teacherId: string } // list for pinning view
  >
  result: QCell[]
}>({
  method: 'post',
  urlFactory: `unstable-pin/list`,
})
