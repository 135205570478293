import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'
import { PacketmetaC2, PublishStage } from '@paper/schema'
import { yupPublishSet } from '@paper/schema/validation'
import { PktColors } from '@paper/styles'
import { Form, Formik, useFormikContext } from 'formik'
import { produce } from 'immer'
import { Txt } from '~src/components'
import { useSubmitPublish } from '../data-publish'
import { ButtonStack } from '../formHelpers'
import { PacketHUD } from './packetHUD'
import PacketNumberField from './packetNumberField'

type PublishDialogProps = {
  onClose(): void
  packet: PacketmetaC2
}
type PublishFormProps = { initialStage: PublishStage; neverPubd: boolean }

type PublishSchema = PacketmetaC2

export const PublishDialog = (props: PublishDialogProps) => {
  const { onClose, packet } = props
  // TODO: error handling
  const mutation = useSubmitPublish({ onSuccess: props.onClose })

  if (!packet) {
    return null
  }

  const initialStage = packet._pub
  const neverPubd = initialStage === 'creating' || initialStage === 'uploaded'
  // initialize form to published if the packet has never been published before
  const initialValues = produce(packet, (draft) => {
    if (neverPubd) {
      draft._pub = 'published'
    }
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onReset={() => onClose()}
      onSubmit={async (values, actions) => {
        const { _pub, contentId, curriculumId, number } = values
        await mutation.mutateAsync({ _pub, contentId, curriculumId, number })
      }}
      validateOnMount={true}
      validationSchema={yupPublishSet}
    >
      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered={true}
        isOpen={!!packet}
        onClose={onClose}
      >
        <ModalOverlay />
        <PublishForm initialStage={initialStage} neverPubd={neverPubd} />
      </Modal>
    </Formik>
  )
}

const getNextStage = (stage: PublishStage): PublishStage => {
  return stage === 'published' ? 'recalled' : 'published'
}

const PublishForm = (props: PublishFormProps) => {
  const { initialStage, neverPubd } = props
  const { dirty, isSubmitting, isValid, setFieldValue, values } =
    useFormikContext<PublishSchema>()

  const nextStage: PublishStage =
    // Only allow going to the next stage once
    values._pub !== initialStage ? undefined : getNextStage(values._pub)

  // Locally clear out publish time so it doesn't say "Published [Old time]"
  const doPublish = (next: PublishStage) => {
    setFieldValue('_pub', next)
    setFieldValue('_updates.publish.time', undefined)
  }

  return (
    <ModalContent as={Form} data-cy="pub-dialog" borderRadius={4}>
      <ModalBody overflow="hidden" pt={6}>
        <Box alignItems="center" display="flex" flexWrap="wrap" mb={6}>
          <Txt fontSize="md" fontWeight="bolder">
            {values.curriculum.name}
          </Txt>
          <Box alignItems="center" display="inline-flex">
            <PacketHUD mx={3} packet={values} />
            <PacketHUD.PublishButton
              next={nextStage}
              onClick={() => doPublish(nextStage)}
              size="xs"
            />
          </Box>
        </Box>
        <PacketNumberField />
      </ModalBody>
      <ModalFooter>
        <ButtonStack>
          <Button data-cy="pub-cancel" size="sm" type="reset" variant="outline">
            Cancel
          </Button>
          <Button
            data-cy="pub-submit"
            isDisabled={!dirty || isSubmitting || !isValid}
            size="sm"
            type="submit"
            colorScheme={neverPubd ? PktColors.Publish : undefined}
          >
            {neverPubd ? 'Publish' : 'Submit'}
          </Button>
        </ButtonStack>
      </ModalFooter>
    </ModalContent>
  )
}
