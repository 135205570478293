import { School, Teacher, XpageSW } from '..'

// note: to future us, this was previously the pinning feature, but still used for cross-network
/** ids for a unique q-cell slot */
export type QCellShared = {
  _id: string // cellId + aStr
  cellId: string // cellId so we can $lookup on a single field
  contentId: string // cross-network lookup
  curriculumId: string // convenient link to jump-to-q
  // for ids
  packetId: string
  qId: string
  syId: number // should be `term`, but can deal with that later
  teacherId: string
}

/////////////////
// QCells
/////////////////
type BaseQ = {
  outOf: number
  qId: string
  qIndex: number
  qLabel: string
  iis: number[]
}

type BaseAnswer = {
  aStr: string
  correct: boolean
  pts: number
}

type AnswerDb = BaseAnswer & {
  count: number
  exampleId: string
}

export type QCellAnswer = AnswerDb & {
  xpf?: XpacketFragment
}

type Cellify<T> = QCellShared & BaseQ & { count: number } & T

export type QCellDb = Cellify<{
  _illuminate: string // todo: not necessary, but useful for debugging
  answers: Record<string, AnswerDb>
}>

// maybe worth slimming this down further at some point
// don't currently need contentId, year
export type QCell = Cellify<{ answers: Record<string, QCellAnswer> }>

export type QAxisItem = BaseQ & {
  answers: Record<string, BaseAnswer>
  id: string
}

/**
 * This is actually Teacher+Packet (which implictly include curriculum)
 * For now, I'm mostly expecting this to be unique to teacher, but we can adjust
 */
export type TeaAxisItem = {
  id: string
  curriculumId: string
  fullName: string
  outOf: number
  packetId: string
  pts: number
  school: School
  teacher: Teacher
  teacherId: string
}

export type XpacketFragment = { id: string; pages: XpageSW[] }
