import {
  Button,
  List,
  Table,
  TableCellProps,
  TableContainer as Tc,
  Tbody,
  Td,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { IcoDownload, IcoQRCode, IcoStack, IcoStaple } from '@paper/icons'
import { DownloadRow } from '@paper/schema'
import { formatQRBackup, sortNumeric } from '@paper/utils'
import { orderBy } from 'lodash'
import { ReactNode, useMemo, useState } from 'react'
import { Ex, HStack, TooltippedIcon } from '~src/components'
import { ExpandeeProps, UghColumn, UghTable } from '~src/components/table'
import type { UseApiQueryResult } from '~src/data/useApiQuery'
import { IntlDT } from '~src/intl'
import { formatUnits } from '~src/utils/messages'
import { LastUpdateDisplay } from '../lastUpdate'
import { IconColumnProps, IconLabel } from '../packetTable/tableColUtils'
import { TableContainer } from '../tableHelpers'
import { DownloadButtonGroup } from './downloadButtonGroup'
import { DownloadTableExport } from './downloadTableExport'
import { lifact, renderPiecesSS } from './printDialogLayout'

export type DownloadTableVariant = 'anyone-with-link' | 'loggedin'

type DownloadTableProps = {
  qResult: UseApiQueryResult<'print.download'>
  variant: DownloadTableVariant
}

export function DownloadTable(props: DownloadTableProps) {
  const { qResult, variant } = props
  const columns = useDownloadTableCols(variant)
  const [selectedRow, setSelectedRow] = useState<string>()

  const exportData = useMemo(() => {
    return orderBy(qResult.data?.downloads, (p) => p.generatedAt)
  }, [qResult.data])

  // sort here since we're dealing with different react-query-s(?)
  const tableData = useMemo(() => {
    if (qResult.data) {
      return sortNumeric(
        qResult.data.downloads.filter((p) => p.rowStatus === 'downloadable'),
        [(p) => p.downloadName, (p) => p.partNumber]
      )
    }
  }, [qResult.data])

  let whatsListed: ReactNode[]
  let item = tableData?.[0]
  if (item) {
    // these should be the same for all rows
    whatsListed = [
      item.whoSnap.schoolName,
      item.packetSnap.groupId,
      formatUnits(tableData.length, 'PDF'),
    ]
  }

  return (
    <TableContainer
      flexGrow={1}
      fontSize="sm"
      gap={4}
      overflowX="hidden"
      qResult={qResult}
    >
      <HStack fontSize="lg" gap={2} whiteSpace="nowrap">
        {renderPiecesSS(whatsListed)}
      </HStack>
      <List spacing={1}>
        {lifact(
          <HStack gap={1}>
            Copy download links to share with an external printer
            <DownloadTableExport data={exportData} />
          </HStack>
        )}
        {lifact(
          <HStack display="inline-flex" gap={1}>
            The links expire{' '}
            <Ex borderRadius="md" fontSize="xs" px={2}>
              {IntlDT.datetime(qResult.data?.expires)}
            </Ex>
          </HStack>
        )}
        {lifact(
          <HStack gap={1}>
            <Button
              aria-label={'Example of clicked button'}
              isDisabled={true}
              leftIcon={<IcoDownload />}
              opacity="1!important"
              size="xs"
              {...DownloadButtonGroup.touchedProps}
            >
              Download
            </Button>{' '}
            history is not saved or shared, and resets when you navigate to or
            away from this page
          </HStack>
        )}
      </List>
      <UghTable
        aria-label="Downloads"
        columns={columns}
        data={tableData}
        Expandee={DownloadExpandee}
        expandeeHeight={140} //{173}
        flexGrow={1}
        onSelect={(item: DownloadRow) => setSelectedRow(item.id)}
        overscan={Infinity} // todo: avoid download local state getting blown away, hoist(?)
        selectedId={selectedRow}
      />
    </TableContainer>
  )
}

type Col = UghColumn<DownloadRow>
const useDownloadTableCols = (variant: DownloadTableVariant) => {
  const nameCol: Col = {
    props: { align: 'start' },
    label: () => 'Name',
    cell: (item) => item.downloadName,
  }

  const downloadCol: Col = {
    props: { dontElipsize: true, width: 100 },
    cell: (item) => {
      return (
        <DownloadButtonGroup
          partLayout="row"
          url={item.downloadUrl}
          variant={variant}
        />
      )
    },
    label: () => {
      return 'Download'
    },
  }

  const partCol: Col = {
    props: { dontElipsize: true, width: 30 },
    label: () => 'Part',
    cell: (item) => {
      return item.partCount > 1 ? item.partNumber : null
    },
  }

  const stapleCol: Col = {
    props: IconColumnProps,
    label: () => (
      <IconLabel icon={IcoStaple} textValue="Staple every _ pages" />
    ),
    // i think it will be least confusing if we display this value even when it's 1 or 2 (meaning no stapling)
    cell: (item) => item.stapleEvery,
  }

  const generateCol: Col = {
    props: { align: 'start' },
    label: () => 'Generated',
    cell: (item) => (
      <LastUpdateDisplay
        lastUpdate={{ user: item.printUser, time: item.generatedAt }}
      />
    ),
  }

  const sheetCol: Col = {
    props: IconColumnProps,
    label: () => (
      <IconLabel icon={IcoStack} textValue="Sheets of Paper (double-sided)" />
    ),
    cell: (item) => item.sheetCount,
  }

  return [nameCol, partCol, downloadCol, stapleCol, sheetCol, generateCol]
}

function DownloadExpandee(props: ExpandeeProps<DownloadRow>) {
  const { checksum, lastQRPageNum } = props.item
  const headings = [
    'Packet',
    'Page',
    <TooltippedIcon
      aria-label="QR value"
      as={IcoQRCode}
      fontSize="md"
      mt={1}
    />,
    'Text code',
  ]

  const labels = [
    [1, 1],
    [2, 1],
    [`Last (${checksum.m})`, `Last (${lastQRPageNum})`],
  ] as const

  const values = [checksum['1_1'], checksum['2_1'], checksum['m_n']]

  const tdProps: TableCellProps = { py: 1, textAlign: 'center' }

  return (
    <Tc
      borderRadius="md"
      borderWidth="1px"
      fontSize="sm"
      maxWidth="600px"
      mx={8}
      my={2}
    >
      <Table variant="simple">
        <Thead>
          <Tr>
            {headings.map((el, idx) => (
              <Td key={idx} {...tdProps}>
                {el}
              </Td>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {labels.map(([packet, page], idx) => (
            <Tr key={idx} fontFamily="mono">
              {[
                packet,
                page,
                values[idx].id,
                formatQRBackup(values[idx].qrb),
              ].map((el, idx) => (
                <Td key={idx} {...tdProps}>
                  {el}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Tc>
  )
}
