import type { Except } from 'type-fest'
import type { FileLocation } from './location'
import type { LastUpdate } from './packetmeta'
import { TicketRubric } from './types.xpacket'

type V1ScanImageData = {
  packetId: string
  pageIndex: number
  teacherId: string
  xpacketId: string
  xpageId: string
}

type V2ScanImageData = V1ScanImageData & {
  manifestId: string
  pts: number
  rubric: TicketRubric
  studentId: string
}

export type ScanStatus =
  | 'error'
  | 'ignore'
  | 'infer'
  | 'infer-blank'
  | 'infer-gap'
  | 'manual'
  | 'no-qr'
  | 'not-paper-qr'
  | 'pending'
  | 'success'
  | 'wrong-location'

export type ScanImageEmbed = {
  _updates?: { manual: LastUpdate }
  /**
   * cdn location
   * Same as intake, but under `usi/7/prod/pages`
   */
  cdn: FileLocation
  /**
   * Paper data
   * (todo: Not sure this is actually partial, but is reused for ImgpError)
   */
  data: Partial<V2ScanImageData>
  /**
   * Fixes by the user
   * todo: should we be keeping a full accounting of changes?...activity log?
   */
  fix?: Partial<V2ScanImageData>
  /** id for the image */
  id: string
  /**
   * Intake location
   * `usi/7/prod/YYYY-MM/{batchNum}/{batchId}_{index}.jpg`
   */
  intake: FileLocation
  /**
   * Page number ignoring blank removal (provided by the scanner)
   *  * e.g. scanner's `front` is always odd, `back` always even (the scanner doesn't know which is our front/back)
   *  * Sheet index is `Math.floor((pageNumAbs - 1) / 2)`
   *  * todo: However fujitsu seems to give us the images in reverse order, so we flip the sheetIndex too
   *  * I don't remember if I figured this out via docs or empirically...
   */
  pageNumAbs: number
  /** Index of the sheet in the batch */
  sheetIndex: number
  /** @see ScanStatus */
  status: ScanStatus
}

/** Data about page from scanner */
export type ScanPageTransfer = Pick<ScanImageEmbed, 'pageNumAbs' | 'sheetIndex'>

/** Abbreviated ScanImage for scanlog view api */
export type ScanImageAbbr = Except<
  ScanImageEmbed,
  'cdn' | 'intake' | 'pageNumAbs'
> & { key: string }

/** Result of imgp processing one image */
export type ImgpOneResult = ScanImageEmbed

export type InferMeta = {
  /** across-sheet-order */
  aso: number
  /** has-anomaly */
  ha: boolean
  /** intra-sheet-order */
  iso: number
  /** percent of sheets with data */
  pd: number
  /** is the batch well-behaved */
  wb: boolean
}

export type ScanBatch = {
  /** infer metadata */
  _infer: InferMeta
  /** update timestamp for crunching */
  _ts: any
  /** processing versions */
  _v: {
    imgp: string
    infer: string
    scan: string
  }
  /** batch number */
  batchNum: number
  /** device-ish details */
  device: {
    bitDepth: number
    model: string
    name: string
    resolution: number
  }
  /** Raw manifest provided by the device */
  deviceManifest: FileLocation
  /** Which will contain version info */
  jobName: string
  /** newly deterministic id */
  id: string
  /** process time */
  processMs: number
  /** date in the manifest file */
  scanDate: number
  /** the scans */
  scans: ScanImageEmbed[]
  /** how to identify on the source (e.g. the folder name) */
  sourceId: string
  /** school year */
  syId: number
  /** processing stages */
  ts: {
    /** initial batch create date (reprocess indicator) */
    initial: number
    /** batch created */
    create: number
    /** intake complete */
    intake: number
    /** process start date */
    process: number
    /** processing complete */
    settled: number
  }
}

export type CrunchBatch = Except<
  ScanBatch,
  'deviceManifest' | 'jobName' | 'scans' | 'sourceId'
> & {
  /** for scanlog  */
  chunks: SheetChunk[]
  /** packet/teacher combos */
  packets: { packetId: string; teacherId: string }[]
  /** for scanviz */
  pageChunks: VizPageChunk[]
  /** for convenience */
  scanCount: number
  /** counts of pages with each status */
  stats: { [key in ScanStatus]?: number }
  /** key times for system status in seconds */
  times: { delay: number; proc: number }
  /** todo: can't remember where this is used */
  xpacketIds: string[]
}

/**
 * ScanBatch variant for the system status api
 */
export type ScanBatchForStatus = Pick<
  CrunchBatch,
  'scanCount' | 'scanDate' | 'times' | 'ts'
>

export type SheetChunk = {
  items: ScanImageAbbr[][]
  startIndex: number
}

export type VizPageChunk = {
  status: ScanStatus
  length: number
}

export type ScanVizBatch = Pick<
  CrunchBatch,
  | 'id'
  | 'batchNum'
  | 'device'
  | 'packets'
  | 'pageChunks'
  | 'scanCount'
  | 'scanDate'
  | 'stats'
  | 'times'
>
