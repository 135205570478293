import { APIs } from '@paper/api-specs'
import { getSchedJobStatus, mapKeyList } from '@paper/utils'
import { orderBy } from 'lodash'
import { useApiQuery } from '~src/data/useApiQuery'
import { setWindowIfCypress } from '~src/utils/cypress'

const staleTime = 10 * 60_000

const medianOfSorted = (sorted: number[]): number => {
  // Return null if the array is empty
  if (sorted.length === 0) {
    return null
  }
  const middle = sorted.length / 2
  // Average the 2 middle values if even length
  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2
  }
  // Else return the middle item
  else {
    return sorted[Math.floor(middle)]
  }
}

export const useSystemStatusData = (isOnLine: boolean) => {
  const qResult = useApiQuery({
    apiSpec: APIs['system.status'],
    queryVars: { body: {} },
    queryFn: async ({ plainFetch }) => {
      let data = await plainFetch()
      const { fetchedAt } = data
      ////////////////////////////
      // Crunch scanbatch stats
      ////////////////////////////
      const { lastN, N } = data.scanbatch
      let RECENT_MIN = 20
      const RECENT_MS = RECENT_MIN * 60_000
      // get recent
      const recents = lastN.filter((p) => fetchedAt - p.ts.settled < RECENT_MS)
      // get median of recents, both [delay, proc]
      const minutesOfRecents = mapKeyList(['delay', 'proc'], (key) =>
        // times are in seconds
        orderBy(recents.map((p) => p.times[key] / 60))
      )

      const scanbatchStats = {
        hasBatches: lastN.length > 0,
        isNormal: minutesOfRecents.delay.every((p) => p < 12),
        latest: {
          settled: lastN[0]?.ts.settled,
        },
        recent: {
          countLabel: recents.length + (recents.length === N ? '+' : ''),
          delayMedian: medianOfSorted(minutesOfRecents.delay),
          intervalMinutes:
            recents.length === lastN.length
              ? // if all recent, update interval
                Math.ceil((fetchedAt - recents.at(-1)?.ts.settled) / 60_000)
              : // else it's the default
                RECENT_MIN,
          procMedian: medianOfSorted(minutesOfRecents.proc),
        },
      }

      // todo: maybe move to server
      const load = data.load && {
        ...data.load,
        isNormal:
          // todo: may want to make no sched/no jobs abnormal eventually...
          // todo: since it might indicate a config issue
          !data.load.loadSched?.length ||
          Object.values(data.load.jobs).every((job) =>
            getSchedJobStatus({
              fetchedAt,
              lastRun: job?.recentSlots[0],
              leewayMinutesRun: 10,
              leewayMinutesStart: 15,
            })
          ),
      }

      return { fetchedAt, load, scanbatchStats }
    },
    useQueryProps: { enabled: isOnLine, refetchInterval: staleTime, staleTime },
  })
  setWindowIfCypress(`__cy[qResult[system.status]]`, qResult)
  return qResult
}
