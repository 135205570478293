import { APIs, ResultOf } from '@paper/api-specs'
import { useRouter } from '@paper/route'
import { ScanStatus } from '@paper/schema'
import { counterMap, objectKeys, sumByCoerce } from '@paper/utils'
import { formatDate } from '@paper/utils/date'
import { orderBy } from 'lodash'
import { createContext, ReactNode, useContext, useMemo } from 'react'
import { FullPageLoading } from '~src/components/status'
import { useApiQuery } from '~src/data/useApiQuery'
import { RD_Internal_Scanviz } from '~src/routelist'

type Stats = {
  batches: number
  denom: number
  infer: number
  inferGap: number
  manual: number
  scansPerDay: [string, number][]
  success: number
}

type ScanVizContext = {
  data: ResultOf<'scanViz.list'>
  emphasize: ScanStatus
  hideUninteresting: boolean
  stats: { shown: Stats; total: Stats }
  zoom: number
}

const crunchStats = (data: ResultOf<'scanViz.list'>): Stats => {
  if (!data) {
    return null
  }

  const infer = sumByCoerce(data, (p) => p.stats.infer)
  const inferGap = sumByCoerce(data, (p) => p.stats['infer-gap'])
  const manual = sumByCoerce(data, (p) => p.stats.manual)
  const success = sumByCoerce(data, (p) => p.stats.success)

  const scansPerDay = counterMap<string>()
  data.forEach((batch) =>
    scansPerDay.increment(
      formatDate(batch.scanDate, 'yyyy-MM-dd'),
      batch.scanCount
    )
  )

  return {
    batches: data.length,
    denom: infer + inferGap + manual + success,
    infer,
    inferGap,
    manual,
    scansPerDay: orderBy([...scansPerDay.counts], (p) => p[0]),
    success,
  }
}

const ScanVizContext = createContext<ScanVizContext>(undefined)
export const useScanVizContext = () => useContext(ScanVizContext)

type ScanVizProviderProps = { children: ReactNode }

export function ScanVizProvider(props: ScanVizProviderProps) {
  const { children } = props
  const { routeData } = useRouter<RD_Internal_Scanviz>()

  const endDate = routeData.endDate
    ? new Date(routeData.endDate).valueOf()
    : NaN

  const qResult = useApiQuery({
    apiSpec: APIs['scanViz.list'],
    queryVars: { body: { endDate } },
    useQueryProps: { enabled: !isNaN(endDate) },
  })

  const ctx: ScanVizContext = {
    data: qResult.data,
    emphasize: routeData.emphasize,
    hideUninteresting: routeData.hide === 'uninteresting',
    stats: null,
    zoom: routeData.zoom ?? 1,
  }

  // filter batches
  ctx.data = useMemo(() => {
    if (!ctx.data || !ctx.hideUninteresting) {
      return ctx.data
    } else {
      return ctx.data.filter((batch) => {
        const uniqStatuses = new Set(objectKeys(batch.stats))
        if (ctx.emphasize) {
          // if `hideUninteresting` and `emphasize`, only include batches that include `emphasize`
          return uniqStatuses.has(ctx.emphasize)
        } else {
          // if no emphasize`, these combos are uninteresting
          const uninterestings: ScanStatus[][] = [
            ['no-qr'],
            ['success'],
            ['success', 'infer-blank'],
          ]
          // keep if statuses don't match all of the uninteresting combos
          return uninterestings.every((combo) => {
            return !(
              uniqStatuses.size === combo.length &&
              combo.every((s) => uniqStatuses.has(s))
            )
          })
        }
      })
    }
  }, [ctx.data, ctx.emphasize, ctx.hideUninteresting])

  ctx.stats = {
    shown: crunchStats(ctx.data),
    total: crunchStats(qResult.data),
  }

  return (
    <FullPageLoading qResult={qResult}>
      <ScanVizContext.Provider value={ctx}>{children}</ScanVizContext.Provider>
    </FullPageLoading>
  )
}
