import { Icon, List } from '@chakra-ui/react'
import {
  IcoCrossNetwork,
  IcoEdit,
  IcoInfo,
  IcoRuGood,
  IcoSecretDoor,
  IcoTimeGrid,
  IcoUnnamed,
} from '@paper/icons'
import { useRouter } from '@paper/route'
import { DirPacket } from '@paper/schema'
import {
  NavigationMenu,
  NavigationMenuList,
} from '@radix-ui/react-navigation-menu'
import { ReactNode, useRef, useState } from 'react'
import { HStack, StackProps, Txt, VSep, VStack } from '~src/components'
import { getTeaAxisId } from '~src/pages/pinGrid/pinGridAirlock'
import { RD_SW_JumpToQ, Routes } from '~src/routelist'
import { NavListSection } from '~src/routelist/navLinks'
import { IcoScanIssue } from '../icoScanIssue'
import { MenuDetailPane, MenuDetailsSection } from '../menu/menuComponents'
import { RouterNavLink } from '../menu/navLink'
import {
  ScanBar,
  ScanStatusTooltip,
  ScoreAvailBar,
  ScoreStatusTooltip,
} from '../pctBar'
import { SWContext, useSWContext } from '../swContext'
import { IcoQ, IcoStd } from '../swMenu/swMenuIcons'
import { CrumbItems, CrumbMode } from '../swMenu/swMenuItems'
import {
  SWMenuPacketPicker,
  SWMenuTeacherPicker,
} from '../swMenu/swMenuPickers'
import { useTeacherContext } from '../teacherAirlock'
import { useUser } from '../userProvider'
import { MenuModuleBase } from './mmBase'

type StudentWorkModuleProps = { keyboard?: ReactNode }

export function StudentWorkModule(props: StudentWorkModuleProps) {
  const swc = useSWContext()
  return swc ? <StudentWorkStuff swc={swc} {...props} /> : null
}

type StudentWorkStuffProps = { swc: SWContext } & StudentWorkModuleProps

function StudentWorkStuff(props: StudentWorkStuffProps) {
  const { swc, keyboard } = props
  const { teacher } = useTeacherContext()
  const { routeData } = useRouter<RD_SW_JumpToQ>()
  const { isInternal } = useUser()

  const { packet } = swc

  const scanIssueCount = packet?.scan?.partial
  const unnamedOpenCount = packet?.scan?.unnamedOpen || 0

  const [crumbMode, setCrumbMode] = useState<CrumbMode>('')

  const nfpRef = useRef<DirPacket>()
  if (packet) {
    // try to prevent flashing?
    nfpRef.current = packet
  }
  const nonFlashingPacket = nfpRef.current

  //////////////////////////////
  // Nav pane
  //////////////////////////////
  const leftWidth = '300px'
  const leftPane = (
    <NavigationMenu orientation="vertical">
      <NavigationMenuList asChild>
        <List p={1} width={leftWidth}>
          <NavListSection title="View by">
            <RouterNavLink
              disabled={!swc.can.q}
              icon={<IcoQ />}
              route={Routes.sw_jumpToQ}
            >
              Question
            </RouterNavLink>
            <RouterNavLink
              disabled={!swc.can.std}
              icon={<IcoStd />}
              route={Routes.sw_jumpToStd}
            >
              Standard
            </RouterNavLink>
            <RouterNavLink
              disabled={!swc.can.time}
              icon={<IcoTimeGrid />}
              route={Routes.sw_time}
            >
              Time
            </RouterNavLink>
            <RouterNavLink
              disabled={!packet?._isTicketPilot}
              icon={<IcoRuGood />}
              route={Routes.sw_rubric}
            >
              Rubric review
            </RouterNavLink>
            <RouterNavLink
              disabled={!packet?.contentId || packet?.type === 'ticket'}
              icon={<IcoCrossNetwork />}
              route={Routes.crossNetwork}
              // todo: probably written this somewhere else!
              data={{
                contentId: packet?.contentId,
                aStr: routeData.f_ans,
                teaId:
                  packet?.teacherId &&
                  getTeaAxisId({
                    packetId: packet.id,
                    teacherId: packet.teacherId,
                  }),
              }}
            >
              Samples across teachers
            </RouterNavLink>
          </NavListSection>
          <NavListSection title="Switch from">
            <CrumbItems
              crumbMode={crumbMode}
              onChange={setCrumbMode}
              packet={nonFlashingPacket}
              teacher={teacher}
            />
          </NavListSection>
          <NavListSection title="Actions" noBorder={!isInternal}>
            <RouterNavLink
              disabled={!swc.can.fix}
              icon={<IcoScanIssue />}
              route={
                packet?._isTicketPilot ? Routes.sw_fixit : Routes.sw_scanlog
              }
            >
              <Txt as="span">
                Fix scan issues{' '}
                {teacher && scanIssueCount > 0 && (
                  <Txt as="span" fontFamily="mono" fontSize="sm">
                    ({scanIssueCount})
                  </Txt>
                )}
              </Txt>
            </RouterNavLink>
            <RouterNavLink
              disabled={!swc.can.name || packet?._isTicketPilot} // todo: probably add to swc.can
              icon={<IcoUnnamed />}
              route={Routes.sw_setStudent}
            >
              <Txt as="span">
                Fix unnamed{' '}
                {teacher && (
                  <Txt as="span" fontFamily="mono" fontSize="sm">
                    ({unnamedOpenCount})
                  </Txt>
                )}
              </Txt>
            </RouterNavLink>
            {packet?.contentId && (
              <RouterNavLink
                icon={<IcoEdit />}
                route={Routes.publish}
                data={{ contentIdOrNew: packet?.contentId }}
              >
                Packet config
              </RouterNavLink>
            )}
          </NavListSection>
          {isInternal && (
            <NavListSection noBorder={true} title="Internal">
              <RouterNavLink
                icon={<IcoSecretDoor />}
                route={Routes.internal_packetfix}
                data={{ contentId: packet?.contentId }}
              >
                Secret packet fixer
              </RouterNavLink>
            </NavListSection>
          )}
        </List>
      </NavigationMenuList>
    </NavigationMenu>
  )

  //////////////////////////////
  // Details pane
  //////////////////////////////
  let details: ReactNode
  const stretchGrow: StackProps = { alignItems: 'stretch', flexGrow: 1 }
  if (crumbMode === 'teachers') {
    details = (
      <MenuDetailsSection
        py={1}
        {...stretchGrow}
        sx={{ '>*': { mt: packet?.teacherId ? '-2px' : null } }}
      >
        <SWMenuTeacherPicker />
      </MenuDetailsSection>
    )
  } else if (crumbMode === 'packets') {
    details = (
      <MenuDetailsSection py={1} {...stretchGrow}>
        <SWMenuPacketPicker packets={swc.packetSwitchList} />
      </MenuDetailsSection>
    )
  }

  const todoFixThisHeight = '173px'

  const rightPane = (
    <MenuDetailPane flexGrow={1} maxWidth="400px" py={0} separator={true}>
      <VStack
        alignItems="stretch"
        justifyContent="space-evenly"
        height={todoFixThisHeight}
        px={6}
      >
        {packet?.teacherId && (
          <>
            <ScanBarSection packet={packet} />
            <ScoreBarSection packet={packet} />
          </>
        )}
      </VStack>
      {details}
    </MenuDetailPane>
  )
  const todoMaybeGrid = 4

  return (
    <MenuModuleBase>
      <VStack alignItems="stretch" gap={2} p={3}>
        <HStack alignItems="stretch" gap={todoMaybeGrid} minHeight="52px">
          <VStack
            alignItems="stretch"
            minWidth={`calc(${leftWidth} - ${todoMaybeGrid / 4}rem)`}
            ml={2} // todo: match NavListSection
          >
            <Txt fontSize="xs">Selected packet</Txt>
            <Txt fontSize="xl">{nonFlashingPacket?.name}</Txt>
          </VStack>
          <VSep boxProps={{ mr: 4 }} />
          {keyboard && (
            <BarSection title="Keyboard shortcuts">{keyboard}</BarSection>
          )}
        </HStack>
        <HStack alignItems="stretch">
          {leftPane}
          {rightPane}
        </HStack>
      </VStack>
    </MenuModuleBase>
  )
}

type BarSectionProps = { children: ReactNode; title: ReactNode; width?: string }

/**
 * @deprecated Need to reconcile with all the other section types...
 */
export function BarSection(props: BarSectionProps) {
  const { children, title, width } = props
  return (
    <VStack
      alignItems="stretch"
      flexShrink={0}
      gap={2}
      userSelect="none"
      width={width}
    >
      <Txt fontSize="xs">{title}</Txt>
      {children}
    </VStack>
  )
}

type ScanBarSectionProps = { packet: DirPacket }

function ScanBarSection(props: ScanBarSectionProps) {
  const { packet } = props
  const title = (
    <ScanStatusTooltip isButton={false} offset={[0, 50]}>
      <HStack as="span" gap={1}>
        Scan status <Icon as={IcoInfo} />
      </HStack>
    </ScanStatusTooltip>
  )
  return (
    <BarSection title={title}>{packet && <ScanBar {...packet} />}</BarSection>
  )
}

function ScoreBarSection(props: ScanBarSectionProps) {
  const { packet } = props
  const title = packet?.type === 'assessment' && (
    <ScoreStatusTooltip>
      <HStack as="span" gap={1}>
        Score Status <Icon as={IcoInfo} />
      </HStack>
    </ScoreStatusTooltip>
  )
  return (
    <BarSection title={title}>
      {packet && <ScoreAvailBar {...packet} />}
    </BarSection>
  )
}
