import * as Yup from 'yup'
import { PublishStage } from '..'

//export const yupEditionRegex = /^(v\d+)|(\d{4})|(\d{2}-\d{2})$/

const NumberFormat = `Must be of the format "X.X" (e.g. 1.12)`
export const yupPacketNumber = Yup.string()
  .nullable() // temporary so we can override on the next line
  .test('nullable-msg-override', NumberFormat, (p) => !!p)
  .trim()
  .required(NumberFormat)
  .max(7, `Cannot exceed 7 characters`)
  .matches(/^\w+\.\w+$/i, NumberFormat)

export const yum_Pub = Yup.string()
  .oneOf<PublishStage>(['published', 'recalled'])
  .required()

export const yupPublishSet = Yup.object({
  _pub: yum_Pub,
  contentId: Yup.string(),
  curriculumId: Yup.string().required(),
  number: yupPacketNumber,
})
