import type { PacketPrintSnapshot, Page, PdfContentSet } from '@paper/schema'
import { chunk, range, times } from 'lodash'

export type MaxPageSubset = Pick<PdfContentSet, 'pages' | 'parts' | 'type'>

/**
 *
 * @example
 * rangeOfLength(4, 4)
 * // [4, 5, 6, 7]
 */
export function rangeOfLen(start: number, length: number) {
  return range(start, start + length)
}

/**
 * Initializes empty `Page` array of `count` length
 */
export function initPages(count: number): Page[] {
  return times(count, () => ({ items: [] }))
}

type Segments = PacketPrintSnapshot['segments']
/**
 * Convert `parts` (array of indexes) to `segments` (array of [startIndex, length])
 * @example
 * const packet = { pages: [{ items: []}], parts: [9], type: 'ticket' }
 * partsToSegments(packet, { postPare: true })
 * // [[0, 1]]
 * partsToSegments(packet, { postPare: false })
 * // [[9, 1]]
 */
export function partsToSegments(
  props: MaxPageSubset,
  options: { postPare: boolean }
): Segments {
  let { pages, parts, type } = props
  if (type === 'ticket') {
    // we pare the pdf for tickets
    // start from 0 if already pared, otherwise start from selected page
    let start = options.postPare ? 0 : parts[0]
    // one segment from first part, with length = pages.length
    return [[start, pages.length]]
  } else {
    let segments: Segments = []
    for (let i = 0; i < parts.length; i++) {
      // one segment per part
      // get length from next part or length of pdf
      let nextPartIndex = parts[i + 1] ?? pages.length
      let segmentLength = nextPartIndex - parts[i]
      segments.push([parts[i], segmentLength])
    }
    return segments
  }
}

/**
 * Returns an array of part lengths
 */
export function getPartLengths(props: MaxPageSubset) {
  const segments = partsToSegments(props, { postPare: true })
  return segments.map(([, segmentLength]) => segmentLength)
}

/**
 * Rounds `pageCount` up to the next even (if > 1) to account for trailing blanks
 * todo: i wonder if i should run this as part of partsToSegments
 * todo: ...the tricky thing is that in the CAT context, it would be confusing to see 4 pages if you know the quiz is 3 pages
 */
export const upToEven = (pageCount: number) => {
  if (pageCount === 1 || pageCount % 2 === 0) {
    return pageCount
  } else {
    return pageCount + 1
  }
}

/**
 * @deprecated todo: parts has different semantics for tickets (which is bad!)
 * @see `partsToSegments`
 */
const _resolveParts = (content: MaxPageSubset) => {
  return content.type === 'ticket' ? [0] : content.parts
}

/**
 * Returns `maxPageIdx` for infer-blank
 * @example
 * let packet = ...
 * // { content: { pages: [{...},{...},{...}], parts: [0], type: 'assessment'}}
 * getMaxPageIdx(packet.content, 2)
 * // if the part is 3 pages long, then this will return `2`
 */
export const getMaxPageIdx = (content: MaxPageSubset, flipPageIdx: number) => {
  const maxPageIndexes = getMaxPageIndexes(content)

  const parts = _resolveParts(content)

  // Get the index of the first part where the flipPageIdx <= its maxPageIdx
  let partIdx = maxPageIndexes.findIndex((maxIdx) => flipPageIdx <= maxIdx)

  // If flipPageIdx is greater than all, then we use the max of the max
  if (partIdx === -1) {
    partIdx = maxPageIndexes.length - 1
  }

  return { maxPageIdx: maxPageIndexes[partIdx], partStartIdx: parts[partIdx] }
}

/**
 * Returns the maxPageIndex of each part
 */
export const getMaxPageIndexes = (content: MaxPageSubset) => {
  const { pages } = content
  const parts = _resolveParts(content)

  // the `maxIndex` of a `part(n)` is `part(n+1) - 1`
  // and the `maxIndex` of the final part is `pages.length - 1`

  // so subtract `1`
  let maxIndexes = parts.map((p) => p - 1)
  // shift to get them in the right spot
  maxIndexes.shift()
  // add the last part
  maxIndexes.push(pages.length - 1)

  return maxIndexes
}

/**
 * @returns
 *  * An array of parts, where each element is
 *    * An array of sheets, where each element is
 *      * An array of pageIndexes
 */
export const sheetifyPacketmeta = (props: MaxPageSubset) => {
  const segments = partsToSegments(props, { postPare: true })
  return segments.map(([start, length]) => chunk(rangeOfLen(start, length), 2))
}
