import { ResultOf } from '@paper/api-specs'
import { times } from 'lodash'
import { useMemo } from 'react'
import { ScanVizSettings } from './scanVizSettings'

type ScanVizShapesProps = {
  data: ResultOf<'scanViz.list'>
  settings: ScanVizSettings
}

export type ScanVizShape = {
  batchNum: number
  deviceName: string
  dots: {
    x: number
    y: number
    fill: string
  }[]
  fill: string
  id: string
  label: string
  length: number
  scanDate: number
  packetId: string
  teacherId: string
}

export const useScanVizShapes = (props: ScanVizShapesProps): ScanVizShape[] => {
  const { data, settings } = props
  return useMemo(() => {
    console.log('[memo] useScanVizShapes')
    if (!data) {
      return []
    }
    let lastDeviceName: string
    return data.map((batch, x) => {
      // tag first batch with deviceName with its name
      let label =
        batch.device.name !== lastDeviceName ? batch.device.name : null
      lastDeviceName = batch.device.name

      // check if batch has single status so we can render as single bar
      const singleStatus =
        batch.pageChunks.length === 1 ? batch.pageChunks[0].status : null

      // get fill from settings based on status, with default
      const fill = settings.full[singleStatus] ?? settings.bar

      // calculate dots
      const dots: ScanVizShape['dots'] = []
      if (!singleStatus) {
        let y = 0
        for (let chunk of batch.pageChunks) {
          // SheetChunk != StatusChunk
          const dotFill = settings.dot[chunk.status]
          if (dotFill) {
            dots.push(
              // current format chunks all statuses
              ...times(chunk.length, (i) => ({ fill: dotFill, x, y: y + i }))
            )
          }
          y += chunk.length
        }
      }

      return {
        batchNum: batch.batchNum,
        deviceName: batch.device.name,
        dots,
        fill,
        id: batch.id,
        label: label,
        length: batch.scanCount,
        packetId: batch.packets[0]?.packetId,
        scanDate: batch.scanDate,
        teacherId: batch.packets[0]?.teacherId,
      }
    })
  }, [data, settings])
}
