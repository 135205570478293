import {
  Box,
  IconProps,
  Input,
  List,
  ListIcon,
  ListItem,
  Spinner,
  SystemProps,
} from '@chakra-ui/react'
import { APIs } from '@paper/api-specs'
import {
  IcoCheckmark,
  IconType,
  IcoValidUntouched,
  IcoWarningCircle,
  IcoX,
} from '@paper/icons'
import { yupPacketNumber } from '@paper/schema/validation'
import { XOR } from '@paper/utils'
import { Field, FieldProps } from 'formik'
import { FC, ReactNode, useMemo } from 'react'
import { Except } from 'type-fest'
import { Ex, Italic, Txt, VStack } from '~src/components'
import { useApiQuery } from '~src/data/useApiQuery'
import { formatUnits } from '~src/utils/messages'
import { FieldUI } from '../formHelpers'

type VStatusMeta = XOR<
  {
    color: IconProps['color']
    as: IconType
  },
  { node: FC<SystemProps> }
>

const vsm = (meta: VStatusMeta): VStatusMeta => meta

const AsyncVStatuses = {
  empty: vsm({
    as: IcoValidUntouched,
    color: 'yellow.500',
  }),
  invalid: vsm({
    as: IcoX,
    color: 'red.600',
  }),
  loading: vsm({
    node: (props) => (
      <Spinner {...props} color="gray.400" speed="1.15s" size="xs" />
    ),
  }),
  pending: vsm({
    as: IcoValidUntouched,
    color: 'gray.300',
  }),
  systemError: vsm({
    as: IcoWarningCircle,
    color: 'orange.500',
  }),
  valid: vsm({
    as: IcoCheckmark,
    color: 'green.500',
  }),
} as const

type AsyncVStatus = keyof typeof AsyncVStatuses

type AsyncVItem = {
  id: string
  message: ReactNode | string
  status: AsyncVStatus
}

type SyncVItem<Val = string> = Except<AsyncVItem, 'status'> & {
  test(value: Val): boolean
  value: Val
}

function syncVItem({ test, value, ...rest }: SyncVItem): AsyncVItem {
  return {
    ...rest,
    status: !value ? 'empty' : test(value) ? 'valid' : 'invalid',
  }
}

const usePacketNumValidation = (props: CondListProps) => {
  let { contentId, curriculumId, dirty, value } = props

  let isValidYup: boolean
  let yupError: Error
  // todo: need to integrate with yup/formik, but at least check that we haven't made an error
  try {
    value = yupPacketNumber.validateSync(value)
    isValidYup = true
  } catch (err) {
    isValidYup = false
    yupError = err
  }

  // todo: need to dedupe with server-side validation
  const conditions: AsyncVItem[] = [
    syncVItem({
      id: 'format',
      message: (
        <Txt as="span">
          <Ex>X.X</Ex> format, e.g. <Ex>1.12</Ex>
        </Txt>
      ),
      test: (value) => /^\w+\.\w+$/i.test(value),
      value,
    }),
    syncVItem({
      id: 'length',
      message: 'Up to 7 characters',
      test: (value) => value.length <= 7,
      value,
    }),
  ]

  const isValidSoFar = conditions.every((p) => p.status === 'valid')
  const hasUglyTempOutOfSyncError = isValidSoFar !== isValidYup
  // A bit cleaner in react-query devtools to pre-calculate
  const prefix = value?.length >= 3 && value.slice(0, 3)
  const asyncEnabled =
    !hasUglyTempOutOfSyncError && isValidSoFar && dirty && !!prefix

  const qResult = useApiQuery({
    apiSpec: APIs['pe.packetNum'],
    queryVars: { searchParams: { curriculumId, prefix } },
    useQueryProps: {
      enabled: asyncEnabled,
      staleTime: 300_000,
    },
  })

  // crunch if we have data
  // todo: probably move to query
  const crunched = useMemo(() => {
    // exclude ourselves and non-matches
    const dupes =
      qResult.data?.filter(
        (p) =>
          p.contentId !== contentId && // Not self
          p._pub !== 'recalled' && // Not recalled
          p.number === value // Same number
      ) ?? []

    if (qResult.isSuccess) {
      const error = dupes.length > 0 && (
        <Txt as="span" color={AsyncVStatuses.invalid.color} fontSize="xs">
          {formatUnits(dupes.length, 'dupe')}
          {dupes.length === 1 ? ':' : ', including:'}{' '}
          <Ex color={AsyncVStatuses.invalid.color}>{dupes[0].name}</Ex>
        </Txt>
      )
      const status: AsyncVStatus = dupes.length > 0 ? 'invalid' : 'valid'
      return { error, status }
    }
  }, [qResult.data, qResult.isSuccess, value])

  let codaBody: ReactNode
  if (hasUglyTempOutOfSyncError || qResult.isError) {
    yupError && console.error(yupError)
    codaBody = (
      <Txt color={AsyncVStatuses.systemError.color}>Something went wrong</Txt>
    )
  } else if (asyncEnabled && crunched?.error) {
    codaBody = crunched.error
  } else if (value && !dirty) {
    codaBody = <Italic>No changes to submit</Italic>
  }

  // And tack on async
  conditions.push({
    id: 'dupes',
    message: 'Unique to this curriculum',
    status: qResult.isFetching
      ? 'loading'
      : qResult.isPending || !isValidSoFar
      ? 'pending'
      : qResult.isSuccess && crunched
      ? crunched.status
      : 'systemError',
  })

  return {
    coda: (
      <Box fontSize="sm" height={4}>
        {codaBody}
      </Box>
    ),
    conditions,
  }
}

type CondStatusProps = Pick<AsyncVItem, 'id' | 'status'>

function CondStatus(props: CondStatusProps) {
  const { id, status } = props

  const meta = AsyncVStatuses[status]
  const sharedProps: SystemProps & {
    'data-key': string
    'data-status': string
  } = {
    'data-key': id,
    'data-status': status,
    marginEnd: 1,
    width: '.75rem', // todo: matching xs spinner
  }

  return meta.node?.(sharedProps) ?? <ListIcon {...sharedProps} {...meta} />
}

type CondListProps = {
  contentId: string
  curriculumId: string
  dirty: boolean
  value: string
}

function CondList(props: CondListProps) {
  const { coda, conditions } = usePacketNumValidation(props)

  return (
    <VStack alignItems="stretch">
      <List fontSize="sm" py={2} spacing={0.5} userSelect="none">
        {conditions.map((item) => {
          return (
            <ListItem
              key={item.id}
              alignItems="center"
              display="flex"
              minHeight="22.5px"
            >
              <CondStatus {...item} />
              {item.message}
            </ListItem>
          )
        })}
      </List>
      {coda}
    </VStack>
  )
}

const PacketNumberField = () => {
  return (
    <Field name="number">
      {({ field, form, meta }: FieldProps) => {
        const dirty =
          field.value !== meta.initialValue ||
          (!meta.initialValue && !field.value)
        return (
          <FieldUI
            input={
              <Input
                data-cy="pkt-num"
                fontFamily="mono"
                type="text"
                {...field}
                onChange={(event) => {
                  // Prevent whitespace
                  const noSpaces = event.target.value.trim()
                  form.setFieldValue(field.name, noSpaces)
                }}
                textAlign="center"
                value={field.value ?? ''}
                w="92px"
              />
            }
            helperText={
              <CondList
                contentId={form.values.contentId}
                curriculumId={form.values.curriculumId}
                dirty={dirty}
                value={field.value}
              />
            }
            label="packet number"
            w="100%"
          />
        )
      }}
    </Field>
  )
}

export default PacketNumberField
