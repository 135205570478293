import { Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/react'
import { Fragment, ReactNode } from 'react'
import { HSep, HStack, Txt, VSep, VStack } from '~src/components'
import { IntlDT, IntlNum } from '~src/intl'
import { DarkMode } from '~src/utils/forceMode'
import { formatPercent2 } from '~src/utils/messages'
import { useScanVizContext } from './scanVizProvider'
import { ScanVizSettings } from './scanVizSettings'

type ScanVizHeaderProps = {}

export function ScanVizHeader(props: ScanVizHeaderProps) {
  return (
    <DarkMode>
      <VStack
        alignItems="stretch"
        color="white"
        flexShrink={0}
        gap={4}
        px={4}
        py={4}
      >
        <ScanVizSettings />
        <HSep />
        <ScanVizStats />
      </VStack>
    </DarkMode>
  )
}

type ScanVizStatsProps = {}

function ScanVizStats(props: ScanVizStatsProps) {
  const { data, stats } = useScanVizContext()
  // todo: better handling for no query yet
  if (!data?.length) {
    return <Txt>No batches</Txt>
  }

  return (
    <HStack gap={4}>
      <StatGroup gap={6}>
        <StatX
          label="Batches shown"
          number={
            <>
              {stats.shown.batches} / {stats.total.batches}
            </>
          }
        />
        <VSep />
        <StatX label="Success+Infers+Manuals" number={stats.total.denom} />
        <VSep />
        <StatX label="Success" number={stats.total.success} />
        <VSep />
        <StatX
          label="Infer"
          number={
            <>
              {stats.total.infer} (
              {formatPercent2(stats.total.infer, stats.total.denom)})
            </>
          }
        />
        <StatX
          label="Infer (gap)"
          number={
            <>
              {stats.total.inferGap} (
              {formatPercent2(stats.total.inferGap, stats.total.denom)})
            </>
          }
        />
        <VSep />
        <StatX
          label="Manual"
          number={
            <>
              {stats.total.manual} (
              {formatPercent2(stats.total.manual, stats.total.denom)})
            </>
          }
        />
        <VSep />

        <StatGroup alignSelf="center" flexWrap="nowrap" gap={2.5}>
          <Txt alignSelf="center" fontSize="xs" textAlign="center" width="45px">
            Scans per day
          </Txt>
          {stats.total.scansPerDay.map(([dateStr, count], idx) => (
            <Fragment key={dateStr}>
              {idx !== 0 && <VSep />}
              <StatX
                label={IntlDT.moDay(new Date(dateStr))}
                number={count}
                size="sm"
              />
            </Fragment>
          ))}
        </StatGroup>
      </StatGroup>
    </HStack>
  )
}

type StatProps = { label: ReactNode; number: ReactNode; size?: 'md' | 'sm' }

function StatX(props: StatProps) {
  let { number } = props
  if (typeof number === 'number' || typeof number === 'bigint') {
    number = IntlNum.plain(number)
  }

  return (
    <Stat>
      <StatLabel
        fontSize={props.size === 'sm' ? 'xs' : null}
        textAlign="center"
        whiteSpace="nowrap"
      >
        {props.label}
      </StatLabel>
      <StatNumber
        fontSize={props.size === 'sm' ? 'lg' : '2xl'}
        fontWeight={300}
        textAlign="center"
        whiteSpace="nowrap"
      >
        {number}
      </StatNumber>
    </Stat>
  )
}
