import { BoxProps, ButtonGroup, Checkbox, useToken } from '@chakra-ui/react'
import { useRouter } from '@paper/route'
import { ScanStatus } from '@paper/schema'
import { filterObject } from '@paper/utils'
import { HStack, ToggleGroup, Txt, VSep } from '~src/components'
import { BadDateInput, today_yyyy_mm_dd } from '~src/components/dateInput'
import { FieldUI, FieldUIProps } from '~src/pages/publish/formHelpers'
import { RD_Internal_Scanviz } from '~src/routelist'
import { useDeepMemo } from '~src/utils/useMemos'
import { useScanVizContext } from './scanVizProvider'

type ScanStatusMap = {
  [key in ScanStatus]?: string
}

export type ScanVizSettings = {
  bar: string
  bg: string
  dot: ScanStatusMap
  full: ScanStatusMap
}

type ScanVizSettingsProps = {}

export function ScanVizSettings(props: ScanVizSettingsProps) {
  const { dispatchStay, routeData, useAirlock } =
    useRouter<RD_Internal_Scanviz>()
  const emphasizeOptions: ScanStatus[] = [
    null,
    'infer',
    'infer-gap',
    'manual',
    'no-qr',
  ]
  const size = 'sm'
  const fieldHeight = '56px'
  const inputHeight = '32px'

  const today = today_yyyy_mm_dd()
  useAirlock({ endDate: today }, !routeData.endDate)

  const emphasize = (
    <ToggleGroup.Root
      colorScheme="blue"
      onChange={(emphasize) =>
        dispatchStay({
          emphasize: emphasize === 'all' ? null : emphasize,
        })
      }
      size={size}
      type="single"
      value={routeData.emphasize ?? 'all'}
    >
      <ButtonGroup isAttached={true}>
        {emphasizeOptions.map((option) => (
          <ToggleGroup.Button key={option ?? 'all'} value={option ?? 'all'}>
            {option ?? 'all'}
          </ToggleGroup.Button>
        ))}
      </ButtonGroup>
    </ToggleGroup.Root>
  )

  const uninteresting = (
    <Checkbox
      height={inputHeight}
      isChecked={routeData.hide === 'uninteresting'}
      onChange={(event) =>
        dispatchStay({
          hide: event.target.checked ? 'uninteresting' : null,
        })
      }
      size={size}
    >
      Hide 'uninteresting' batches
    </Checkbox>
  )

  const date = (
    <BadDateInput
      iconButtonProps={{ colorScheme: 'blue' }}
      max="today"
      onChange={(value) => dispatchStay({ endDate: value?.string })}
      size={size}
      value={routeData.endDate}
    />
  )

  const fieldProps: Partial<FieldUIProps<any>> = {
    height: fieldHeight,
    labelPos: 'inline',
    width: 'auto',
  }

  return (
    <HStack gap={4}>
      <FieldUI
        {...fieldProps}
        helperText={
          <Txt as="span" fontSize="xs" opacity={0.75}>
            (10 days ending on)
          </Txt>
        }
        input={date}
        label="Date"
      />
      <VSep boxProps={{ height: inputHeight }} />
      <FieldUI {...fieldProps} input={emphasize} label="Emphasize" />
      <VSep boxProps={{ height: inputHeight }} />
      <FieldUI {...fieldProps} input={uninteresting} label="Filter batches" />
    </HStack>
  )
}

export const useScanVizSettings = () => {
  const levers = useScanVizContext()
  const tokens: BoxProps['color'][] = [
    'white',
    'cyan.200',
    'yellow',
    'red.300',
    'red.200',
  ]
  const [infer, inferGap, manual, noQr, noQrFull] = useToken(
    'colors',
    tokens as any
  )
  const fatal = 'black'

  const shared: ScanStatusMap = {
    error: fatal,
    pending: fatal,
    'wrong-location': fatal,
  }

  const emphasize = (map: ScanStatusMap) => {
    // only color emphasized if not hiding uninteresting
    // the reason is that it's useful to see the whole pattern around `manual`s when zoomed in
    return levers.emphasize && !levers.hideUninteresting
      ? filterObject(map, ([key]) => key === levers.emphasize)
      : map
  }

  // todo: regular useMemo?
  const settings = useDeepMemo<ScanVizSettings>({
    bar: 'rgb(51,51,51)',
    bg: '#4c4c4c',
    dot: emphasize({
      ...shared,
      infer,
      'infer-gap': inferGap,
      manual,
      'no-qr': noQr,
    }),
    full: emphasize({
      ...shared,
      'no-qr': noQrFull,
    }),
  })

  return { levers, settings }
}
