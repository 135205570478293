import { Button } from '@chakra-ui/react'
import {
  IcoCrossNetwork,
  IcoDownload,
  IcoEdit,
  IcoExternalLink,
  IcoMultiparter,
  IcoPacket,
  IcoStaple,
  IcoStapleless,
  IcoTicket,
} from '@paper/icons'
import { useLink, useRouter } from '@paper/route'
import type { DirPacket } from '@paper/schema'
import { TableContainer } from '~src/blocks/tableHelpers'
import { BLink, GhostTag, HStack, Separator } from '~src/components'
import { ExpandeeProps, UghColumn, UghTable } from '~src/components/table'
import { useLookupInDirData } from '~src/data/data-directory'
import { usePacketListData } from '~src/data/data-packets'
import { RD_Home_Teacher, Routes } from '~src/routelist'
import { formatUnits } from '~src/utils/messages'
import { useDownload } from '~src/utils/useDownload'
import { PageCounts } from '../pageCounts'
import { TeacherPrintDialog } from '../pdfBuilder/printDialog'
import { expandeeBtnProps } from './expandeeUtils'

type PacketTableProps = { columns: UghColumn<DirPacket>[] }

export function PacketTable(props: PacketTableProps) {
  const { dispatchStay, routeData } = useRouter<RD_Home_Teacher>()
  const { data, qResult } = usePacketListData()
  const { columns } = props

  return (
    <TableContainer qResult={qResult}>
      <UghTable
        aria-label="Packet list"
        data-cy="home-packet-table"
        columns={columns}
        data={data}
        Expandee={PacketExpandee}
        expandeeHeight={56}
        height="100%"
        onSelect={(item: DirPacket) =>
          dispatchStay({ packetId: item.id, pgId: item.number })
        }
        selectedId={routeData.packetId}
      />
    </TableContainer>
  )
}

/**
 * Expandee for the packet table
 */
export function PacketExpandee(props: ExpandeeProps<DirPacket>) {
  const { height, item } = props

  const { currentRoute } = useRouter()

  // disallow print for teacher view if already scanned,
  // and disallow for curriculum and school views
  // obviously may want to parameterize this differently...
  const uglyIsPrintDisallowed =
    item.pub.stage !== 'published' ||
    (!!item.scan?.count && currentRoute === Routes.home_teacher) ||
    currentRoute !== Routes.home_teacher
  const downloadOrig = useDownload(item.links.original)

  const pageCount = item.pages.length
  const partCount = item.parts.length
  const isStapled = pageCount > 2
  const isTicket = item.type === 'ticket'
  const stapleIcon = isStapled
    ? IcoStaple
    : isTicket
    ? IcoTicket
    : IcoStapleless

  return (
    <HStack
      fontSize="xs"
      gap={5}
      height={`${height}px`}
      ml={12}
      overflowX="scroll"
      px={2}
      sx={{ 'a,button': { flexShrink: 0 } }}
    >
      {!isTicket && (
        <GhostTag
          flexShrink={0}
          label={formatUnits(partCount, 'part')}
          leftIcon={IcoMultiparter}
        />
      )}
      <GhostTag
        flexShrink={0}
        label={<PageCounts packet={item} variant="long" />}
        leftIcon={stapleIcon}
      />
      <Separator orientation="vertical" style={{ marginBlock: '.75rem' }} />
      {!uglyIsPrintDisallowed && <TeacherPrintDialog packet={item} />}
      <ViewOrEditPacketLink {...item} />
      <Button
        {...expandeeBtnProps}
        leftIcon={<IcoDownload />}
        onClick={downloadOrig}
      >
        Download original
      </Button>
      {item.links.external && (
        <BLink
          {...expandeeBtnProps}
          href={item.links.external}
          leftIcon={<IcoExternalLink />}
          isExternal={true}
        >
          External materials
        </BLink>
      )}
      <CrossNetworkLink {...item} />
    </HStack>
  )
}

function CrossNetworkLink(props: DirPacket) {
  const { contentId, questions, type } = props
  const linkProps = useLink(Routes.crossNetwork.mergeAction({ contentId }))

  // not all views have scan info, so use guess a bit...
  const defNotScanned = props.scan && props.scan.count === 0
  const mayHavePins =
    props.print.date &&
    type === 'assessment' &&
    questions.length > 0 &&
    !defNotScanned

  if (!mayHavePins) {
    return null
  }

  return (
    <BLink {...expandeeBtnProps} {...linkProps} leftIcon={<IcoCrossNetwork />}>
      View samples across teachers
    </BLink>
  )
}

function ViewOrEditPacketLink(props: DirPacket) {
  const { curriculumId } = props
  const { curriculumRecord } = useLookupInDirData({ curriculumId })
  const linkProps = useLink(
    Routes.publish.mergeAction({
      contentIdOrNew: props.contentId,
      curriculumId,
    })
  )
  const canEdit = curriculumRecord?.canEdit
  return (
    <BLink
      {...expandeeBtnProps}
      {...linkProps}
      leftIcon={canEdit ? <IcoEdit /> : <IcoPacket />}
    >
      Packet config
    </BLink>
  )
}
